.logo {
	background-image: url(./assets/img/layout/logo-couleur.svg);
}

@media screen and (min-width: 982px) {
	.logo {
		background-image: url(./assets/img/layout/logo.svg);
	}

	.page-talents .logo {
		background-image: url(./assets/img/layout/logo-talent.svg);
	}

	#MainNavbar.affix .logo {
		background-image: url(./assets/img/layout/logo-couleur.svg);
	}
}


.mobile-logo,
.logo-footer {
	background-image: url(./assets/img/layout/logo.svg);
}

.affix .mobile-logo {
	background-image: url(./assets/img/layout/logo-couleur.svg);
}

/*#HomeHeader::after {
	background-image: url(./assets/img/home/Banner.jpg);
}*/
#Section01 {
	background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjBweCIgaGVpZ2h0PSI2MHB4IiB2aWV3Qm94PSIwIDAgNjAgNjAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+PHRpdGxlPmNvcm5lcjwvdGl0bGU+PGcgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTEzNjQuMDAwMDAwLCAtMzkzNi4wMDAwMDApIiBmaWxsPSIjRjg2RTdGIj48cG9seWdvbiBwb2ludHM9IjEzNjQgMzkzNiAxMzY0IDM5NjYuODI3NjcgMTM5My4zMTU3MiAzOTY2LjgyNzY3IDEzOTMuMzE1NzIgMzk5NiAxNDI0IDM5OTYgMTQyNCAzOTM2Ij48L3BvbHlnb24+PC9nPjwvZz48L3N2Zz4=);
}
#Section03,
#PlatformHeader {
	background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjBweCIgaGVpZ2h0PSI2MHB4IiB2aWV3Qm94PSIwIDAgNjAgNjAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+PHRpdGxlPmNvcm5lcjwvdGl0bGU+PGcgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTEzNjQuMDAwMDAwLCAtMzE2OC4wMDAwMDApIiBmaWxsPSIjRjg2RTdGIj48cG9seWdvbiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMzk0LjAwMDAwMCwgMzE5OC4wMDAwMDApIHNjYWxlKDEsIC0xKSB0cmFuc2xhdGUoLTEzOTQuMDAwMDAwLCAtMzE5OC4wMDAwMDApICIgcG9pbnRzPSIxMzY0IDMxNjggMTM2NCAzMTk4LjgyNzY3IDEzOTMuMzE1NzIgMzE5OC44Mjc2NyAxMzkzLjMxNTcyIDMyMjggMTQyNCAzMjI4IDE0MjQgMzE2OCI+PC9wb2x5Z29uPjwvZz48L2c+PC9zdmc+);
}
#Section04 {
	background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjBweCIgaGVpZ2h0PSI2MHB4IiB2aWV3Qm94PSIwIDAgNjAgNjAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+PHRpdGxlPmNvcm5lcjwvdGl0bGU+PGcgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTE2LjAwMDAwMCwgLTM4NjAuMDAwMDAwKSIgZmlsbD0iI0Y5QzhDQSI+PHBvbHlnb24gdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNDYuMDAwMDAwLCAzODkwLjAwMDAwMCkgc2NhbGUoLTEsIC0xKSB0cmFuc2xhdGUoLTQ2LjAwMDAwMCwgLTM4OTAuMDAwMDAwKSAiIHBvaW50cz0iMTYgMzg2MCAxNiAzODkwLjgyNzY3IDQ1LjMxNTcxNzggMzg5MC44Mjc2NyA0NS4zMTU3MTc4IDM5MjAgNzYgMzkyMCA3NiAzODYwIj48L3BvbHlnb24+PC9nPjwvZz48L3N2Zz4=);
}
@media screen and (min-width: 769px) {
	#Section01 .section-home__wrapper:after {
		background-image: url(./assets/img/home/arrow.svg);
	}

	#Section02 .section-home__wrapper:after {
		background-image: url(./assets/img/home/infini.svg);
	}
	#Section03 .section-home__wrapper:after {
		background-image: url(./assets/img/home/bulle.svg);
	}
	#Section04 .section-home__wrapper:after {
		background-image: url(./assets/img/home/fading-logo.svg);
	}

	#LoginView .section-platform__wrapper:after,
	#ContactView .section-platform__wrapper:after {
		background-image: url(./assets/img/layout/logo-shadow.svg);
	}
}


/**
 * Webapp
 */
.webapp-icon--search {
 	background-image: url(./assets/img/platform/webapp/loupe.svg);
}

.webapp-icon--mail {
 	background-image: url(./assets/img/platform/webapp/mail.svg);
}

.webapp-icon--menu {

 	background-image: url(./assets/img/platform/webapp/menu.svg);
}

.webapp-icon--filter {
 	background-image: url(./assets/img/platform/webapp/filters.svg);
}

/**
 * Platform page
 */
@media screen and (min-width: 769px) {
	#PlatformSoftBg01 .section-platform {
		background-image: url(./assets/img/platform/filter-bg.svg);
	}

	#PlatformSoftBg02 .section-platform {
		background-image: url(./assets/img/platform/bars.svg);
	}

	#PlatformSection03 .section-platform__wrapper:after {
		background-image: url(./assets/img/platform/points.svg);
	}

	#PlatformSection04 .section-platform__wrapper:after {
		background-image: url(./assets/img/platform/bulle.svg);
	}

	#PlatformSection05 .section-platform__wrapper:after {
		background-image: url(./assets/img/platform/element.svg);
	}

	#PlatformSection01 .webapp::after {
		background-image: url(./assets/img/platform/picto-platform.svg);
	}

	#PlatformSection04 .webapp::after {
		background-image: url(./assets/img/platform/picto-platform02.svg);
	}

	#TalentsSection03 .webapp::after {
		background-image: url(./assets/img/talents/check.svg);
	}

	#TalentsSection05 .webapp::after {
		background-image: url(./assets/img/talents/infinite.svg);
	}
}

/**
 * Talents page 
 */

@media screen and (min-width: 769px) {
	#TalentsTakeBackBg .section-platform__wrapper:after {
		background-image: url(./assets/img/talents/filter.svg);
	}
	#TalentsSection03 .section-platform__wrapper:after {
		background-image: url(./assets/img/talents/check-opac.svg);
	}
	#TalentsHereForYouBg .section-platform__wrapper:after {
		background-image: url(./assets/img/talents/infinite-opac.svg);
	}
}
/**
 * About page 
 */
#Chrono {
	background-image: url(./assets/img/about/chronology-90.svg);
}

@media screen and (min-width: 769px) {
	#Chrono {
		background-image: url(./assets/img/about/chronology.svg);
	}
}

.about-investors {
	background-image: url(./assets/img/about/inestors.svg);
}

/**
 * Technology Page 
 */
@media screen and (min-width: 769px) {
	#TechnologySection02 .section-platform__wrapper:after {
		background-image: url(./assets/img/technology/man-shadow.svg);
	}

	#TechnologySection03 .section-platform__wrapper:after {
		background-image: url(./assets/img/technology/loupe-shadow.svg);
	}

	#TechnologySection04 .section-platform__wrapper:after {
		background-image: url(./assets/img/technology/refresh.svg);
	}

	#TechnologySection05 .section-platform__wrapper:after {
		background-image: url(./assets/img/technology/cog.svg);
	}

	#TechnologySection06 .section-platform__wrapper:after {
		background-image: url(./assets/img/technology/cadenas-shadow.svg);
	}

	#TechnologySection07 .section-platform__wrapper:after {
		background-image: url(./assets/img/technology/electro-cardio-shadow.svg);
	}

	#TechnologySection07 {
		background-image: url(./assets/img/technology/web-corner.svg);
	}

	#TechnologySection03 .webapp:after {
		background-image: url(./assets/img/technology/loupe.svg);
	}
}
/**
 * Webapp
 */
.webapp-number--68:before {
	background-image: url(./assets/img/platform/webapp/68percent.png);
}
.webapp-number--71:before {
	background-image: url(./assets/img/platform/webapp/71percent.png);
}
.webapp-number--75:before {
	background-image: url(./assets/img/platform/webapp/75percent.png);
}