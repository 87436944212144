
@font-face {
	src: url(./assets/fonts/Gilroy/Gilroy-Extrabold.otf);
	font-family: Gilroy;
	font-weight: 700;
	font-style: normal;
}
@font-face {
	src: url(./assets/fonts/Gilroy/Gilroy-Bold.otf);
	font-family: Gilroy;
	font-weight: 600;
	font-style: normal;
}
@font-face {
	src: url(./assets/fonts/Gilroy/Gilroy-BoldItalic.otf);
	font-family: Gilroy;
	font-weight: 600;
	font-style: italic;
}
@font-face {
	src: url(./assets/fonts/Gilroy/Gilroy-Regular.otf);
	font-family: Gilroy;
	font-weight: 300;
	font-style: normal;
}
@font-face {
	src: url(./assets/fonts/Gilroy/Gilroy-RegularItalic.otf);
	font-family: Gilroy;
	font-weight: 300;
	font-style: italic;
}
@font-face {
	src: url(./assets/fonts/Gilroy/Gilroy-Semibold.otf);
	font-family: Gilroy;
	font-weight: 500;
	font-style: normal;
}
@font-face {
	src: url(./assets/fonts/Gilroy/Gilroy-SemiboldItalic.otf);
	font-family: Gilroy;
	font-weight: 500;
	font-style: italic;
}

@font-face {
	src: url(./assets/fonts/Graphik/Graphik-Bold.otf);
	font-family: Graphik;
	font-weight: 600;
	font-style: normal;
}
@font-face {
	src: url(./assets/fonts/Graphik/Graphik-BoldItalic.otf);
	font-family: Graphik;
	font-weight: 600;
	font-style: italic;
}
@font-face {
	src: url(./assets/fonts/Graphik/Graphik-Regular.otf);
	font-family: Graphik;
	font-weight: 300;
	font-style: normal;
}
@font-face {
	src: url(./assets/fonts/Graphik/Graphik-RegularItalic.otf);
	font-family: Graphik;
	font-weight: 300;
	font-style: italic;
}
@font-face {
	src: url(./assets/fonts/Graphik/Graphik-Semibold.otf);
	font-family: Graphik;
	font-weight: 500;
	font-style: normal;
}
@font-face {
	src: url(./assets/fonts/Graphik/Graphik-SemiboldItalic.otf);
	font-family: Graphik;
	font-weight: 500;
	font-style: italic;
}

@import "~slick-carousel/slick/slick.css";